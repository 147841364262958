import { Suspense, lazy } from 'react';

import { getModuleFromHost } from '../helpers/getModuleFromHost';
import { ClientWrapper } from './ClientWrapper';

const Checkout = lazy(() => import('./Checkout/Checkout'));
const Engager = lazy(() => import('./Engager/Engager'));
const PropertyExplorer = lazy(() => import('./PropertyExplorer/PropertyExplorer'));

export const DomainRouter = () => {
  const host = window.location.host;
  const module = getModuleFromHost(host);

  switch (module) {
    case 'checkout':
      return (
        <Suspense fallback={null}>
          <Checkout />;
        </Suspense>
      );

    case 'property-explorer':
      return (
        <ClientWrapper>
          <Suspense fallback={null}>
            <PropertyExplorer />
          </Suspense>
        </ClientWrapper>
      );

    case 'engager':
      return (
        <ClientWrapper>
          <Suspense fallback={null}>
            <Engager />
          </Suspense>
        </ClientWrapper>
      );

    default:
      return null;
  }
};
