import { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { Error } from './Error';
import { ClientWrapper } from './routes/ClientWrapper';
import { CustomDomainRouter } from './routes/CustomDomainRouter';
import { DomainRouter } from './routes/DomainRouter';
import Root from './routes/Root';

const Modal = lazy(() => import('./routes/Modal/Modal'));
const Checkout = lazy(() => import('./routes/Checkout/Checkout'));
const Engager = lazy(() => import('./routes/Engager/Engager'));
const PropertyExplorer = lazy(() => import('./routes/PropertyExplorer/PropertyExplorer'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <Error />,
    children: [
      // We have 2 routes per module to deal with the 2 ways of getting the company:
      // 1. From the first segment of the path (:companyUuid)
      // 2. From the search option (?company=:companyUuid)
      {
        path: 'property-explorer/:companyUuid/:uuid',
        element: (
          <ClientWrapper>
            <Suspense fallback={null}>
              <PropertyExplorer />
            </Suspense>
          </ClientWrapper>
        ),
      },
      {
        path: 'property-explorer/:uuid',
        element: (
          <ClientWrapper>
            <Suspense fallback={null}>
              <PropertyExplorer />
            </Suspense>
          </ClientWrapper>
        ),
      },
      {
        path: 'modal/:uuid',
        element: (
          <Suspense fallback={null}>
            <Modal />
          </Suspense>
        ),
      },
      {
        path: 'engager/:companyUuid/:uuid',
        element: (
          <ClientWrapper>
            <Suspense fallback={null}>
              <Engager />
            </Suspense>
          </ClientWrapper>
        ),
      },
      {
        path: 'engager/:uuid',
        element: (
          <ClientWrapper>
            <Suspense fallback={null}>
              <Engager />
            </Suspense>
          </ClientWrapper>
        ),
      },
      {
        path: 'checkout/:companyUuid/:uuid',
        element: (
          <Suspense fallback={null}>
            <Checkout />
          </Suspense>
        ),
      },
      {
        path: 'checkout/:uuid',
        element: (
          <Suspense fallback={null}>
            <Checkout />
          </Suspense>
        ),
      },
      {
        // With 2 segments in the path (and module not being the first one)
        // we asume the first is companyUuid and the second projectUuid.
        // We try to deduct module from the domain name
        path: ':companyUuid/:uuid',
        element: <DomainRouter />,
      },
      {
        // With just one or no uuid and no module, we need to check
        // the custom subdomains table, see if there's a match
        path: ':uuid?',
        element: <CustomDomainRouter />,
      },
    ],
  },
]);
