import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { getStorefrontUrl } from '~/helpers/getStorefrontUrl';
import { getToken } from '~/helpers/getToken';
import storage from '~/storage';

export type useClientsArgs = {
  companyUuid?: string;
};

export const useClients = ({ companyUuid }: useClientsArgs) => {
  const [token, setToken] = useState<string>(getToken());

  const getClientTokens = useCallback(async () => {
    try {
      const url = getStorefrontUrl(`/api/v1/clients/${companyUuid}/credentials`).toString();
      const response = axios.get(url);

      const token = (await response).data[0];

      if (token) {
        try {
          window.sessionStorage.setItem('token', token);
        } catch (e) {
          storage({
            token,
          });
        }

        setToken(token);
      }
    } catch (e) {
      console.error(e);
    }
  }, [companyUuid]);

  useEffect(() => {
    getClientTokens();
  }, [getClientTokens]);

  return { token };
};
