import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { getStorefrontUrl } from '~/helpers/getStorefrontUrl';

export type SubdomainModuleResult = {
  isLoading: boolean;
  company_uuid: string | null;
  module_name: string | null;
};

const empty = { company_uuid: null, module_name: null };

export const useSubdomainModule = (subdomain: string) => {
  const [moduleSubdomain, setModuleSubdomain] = useState<SubdomainModuleResult | null>(null);
  const endpointUrl = getStorefrontUrl(
    `/api/v1/clients/subdomain_module?subdomain=${subdomain}`,
  ).toString();

  const getSubdomainModule = useCallback(async () => {
    try {
      const response = axios.get(endpointUrl);

      const result = (await response).data;
      setModuleSubdomain({ isLoading: false, ...result });
    } catch (e) {
      console.error(e);
      setModuleSubdomain({ isLoading: false, ...empty });
    }
  }, [endpointUrl]);

  useEffect(() => {
    getSubdomainModule();
  }, [getSubdomainModule]);

  return moduleSubdomain || { isLoading: true, ...empty };
};
