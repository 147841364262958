import { Suspense, lazy } from 'react';
import { useParams } from 'react-router-dom';

import { getModuleFromHost } from '../helpers/getModuleFromHost';
import { useSubdomainModule } from '../hooks/useSubdomainModule';
import { ClientWrapper } from './ClientWrapper';

const Checkout = lazy(() => import('./Checkout/Checkout'));
const Engager = lazy(() => import('./Engager/Engager'));
const PropertyExplorer = lazy(() => import('./PropertyExplorer/PropertyExplorer'));

export const CustomDomainRouter = () => {
  // It could be either a project or company uuid
  // If a company subdomain is found, then we asume uuid is a projectUuid
  // If no company subdomain is found, then we asume uuid is a companyUuid
  const { uuid } = useParams();

  const host = window.location.host;
  const defaultModuleName = getModuleFromHost(host);

  const hostname = window.location.hostname;
  const {
    isLoading,
    module_name: customModuleName,
    company_uuid: customCompanyUuid,
  } = useSubdomainModule(hostname);

  const moduleName = customModuleName || defaultModuleName;
  const companyUuid = customModuleName ? customCompanyUuid : uuid;

  if (isLoading) return null;

  switch (moduleName) {
    case 'checkout':
      return (
        <Suspense fallback={null}>
          <Checkout explicitCompanyUuid={companyUuid} />
        </Suspense>
      );

    case 'property-explorer':
      return (
        <ClientWrapper explicitCompanyUuid={companyUuid}>
          <Suspense fallback={null}>
            <PropertyExplorer />
          </Suspense>
        </ClientWrapper>
      );

    case 'engager':
      return (
        <ClientWrapper explicitCompanyUuid={companyUuid}>
          <Suspense fallback={null}>
            <Engager />
          </Suspense>
        </ClientWrapper>
      );

    default:
      return null;
  }
};
